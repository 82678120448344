


























































































































































































import Vue from "vue";

import eventsRegister from "@/services/events-register";
import { createRequest, getSetup } from "../services/api-connector";
import { mapGetters } from "vuex";

export default Vue.extend({
  data() {
    return {
      eventsRegister,

      videoWidth: 320,

      isQ1Open: false,
      isQ2Open: false,
      isQ3Open: false,
      isQ4Open: false,
      isQ5Open: false,
      isQ6Open: false,
      isQ7Open: false,
      isQ8Open: false,
      isQ9Open: false,
      isQ10Open: false,
      isQ11Open: false,

      deliveryRate1kgCN: 0,
      deliveryRate1kgUSA: 0,

      insurancePercent: 0,
    };
  },
  computed: {
    ...mapGetters("user", ["userCountryCode"]),
  },
  created() {
    createRequest(
      // get delivery cost CN
      "deliveryCost",
      {
        FromCountryId: 287, // CN
        ToCountryId: 285, // Ukraine
        TypeRouteId: null,
        Pr302: 11102, // Air - transportation type
        pr409: null,
        CustomerId: null,
      },
      (responseData: any) => {
        // {Value: 9}
        this.deliveryRate1kgCN = responseData.Value;
      },
      () => true
    );
    createRequest(
      // get delivery cost USA
      "deliveryCost",
      {
        FromCountryId: 284, // USA
        ToCountryId: 285, // Ukraine
        TypeRouteId: null,
        Pr302: 11102, // Air - transportation type
        pr409: null,
        CustomerId: null,
      },
      (responseData: any) => {
        // {Value: 9}
        this.deliveryRate1kgUSA = responseData.Value;
      },
      () => true
    );

    // get insurance percent
    getSetup([71], (responseData: any) => {
      // Success callback
      // [{SetupId: 71, Value: 2},1: {SetupId: 68, Value: 7},2: {SetupId: 69, Value: 2},...]
      responseData.forEach((itemDB: any) => {
        if (itemDB.SetupId === 71) this.insurancePercent = itemDB.Value;
      });
    });
  },
});
