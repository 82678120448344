












































import Vue from "vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default Vue.extend({
  props: {
    // priceUSA: Number,
    // priceCN: Number,
    deliveryRate: Number,
    minWeightLimit: Number,
  },
  components: { VueSlider },
  data() {
    return {
      countryType: false,
      sliderValue: 0.1,
      sliderData: [
        0.1,
        0.3,
        0.5,
        0.7,
        1,
        1.5,
        2,
        3.5,
        5,
        8,
        10,
        15,
        20,
        25,
        30,
      ],
      sliderMarks: [0.1, 0.5, 1, 2, 5, 10, 20, 30],
    };
  },
  computed: {
    sliderPrice(): number {
      const result =
        this.sliderValue <= this.minWeightLimit
          ? this.deliveryRate * this.minWeightLimit
          : this.sliderValue * this.deliveryRate;
      return Math.round((result + Number.EPSILON) * 100) / 100;
    },
  },
});
