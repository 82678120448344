



















































































































import Vue from "vue";

import OnboardingPrices from "./parts/OnboardingPrices.vue";
import OnboardingSlider from "./parts/OnboardingSlider.vue";
import Help from "@/components/Help.vue";
import { mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  components: {
    OnboardingPrices,
    OnboardingSlider,
    Help,
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        // autoHeight: !this.appstate.isAppGuideActive,
        calculateHeight: true,
        observer: true,
        noSwipingSelector: ".onboarding-slider",
      },
      activeIndex: 0,
      isFirstSlider: true,
      isLastSlider: false,
    };
  },
  mounted() {
    //@ts-ignore
    this.onboardingSlider.on("slideChange", this.onSlideChanged);
  },
  computed: {
    ...mapGetters("root", ["deliveryRates", "refferalRate"]),
    ...mapGetters("user", ["userCountryCode"]),
    isFromRouter(): boolean {
      return this.$route.name === "Help";
    },
    deliveryRate(): number {
      //@ts-ignore
      return this.deliveryRates[this.userCountryCode];
    },
    minWeightLimit(): number {
      const limits = {
        USA: 1,
        UA: 0.5,
      };

      //@ts-ignore
      return limits[this.userCountryCode];
    },
  },
  methods: {
    ...mapMutations("root", ["setAppGuideActive"]),
    onSlideChanged() {
      //@ts-ignore
      const { realIndex, isEnd, isBeginning } = this.onboardingSlider;
      this.activeIndex = realIndex;
      this.isFirstSlider = isBeginning;
      this.isLastSlider = isEnd;
    },
    onNext() {
      //@ts-ignore
      this.onboardingSlider.slideNext();
    },
    onPrev() {
      //@ts-ignore
      this.onboardingSlider.slidePrev();
    },
    onFinish() {
      this.setAppGuideActive(true);
    },
  },
});
