




























import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  props: {
    deliveryRate: Number,
    // priceUSA: Number,
    // priceCN: Number,
  },
  computed: {
    ...mapGetters("root", ["buyoutPercent", "deliveryTime"]),
  },
});
